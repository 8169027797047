import React from 'react';
import ModalVideo from 'react-modal-video';
import {Link} from 'gatsby'
import { useIntl } from "gatsby-plugin-intl"

const MainBanner = () => {
    const intl = useIntl()
    return (
        <React.Fragment>
            <div className="main-banner-item item-bg2">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container">
                            <div className="banner-item-content">
                                <h1>{intl.formatMessage({ id: "home.mainBanner.bannerItemContent.1" })}</h1>
                                <p>{intl.formatMessage({ id: "home.mainBanner.bannerItemContent.2" })}</p>
                                
                                <div className="btn-box">
                                    <Link to="/about-us" className="default-btn">
                                        <i className="flaticon-right"></i> 
                                        {intl.formatMessage({ id: "home.mainBanner.bannerItemContent.buttonAboutUs" })} <span></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MainBanner;
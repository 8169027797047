import React from 'react';
import NavbarTwo from "../components/App/NavbarTwo"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"

import MainBanner from '../components/MachineLearningAISolutions/MainBanner';
import Solutions from '../components/MachineLearningAISolutions/Solutions';
import AboutArea from '../components/MachineLearningAISolutions/AboutArea';
import Services from '../components/MachineLearningAISolutions/Services';
import TeamMembers from '../components/MachineLearningAISolutions/TeamMembers';
import Testimonials from '../components/MachineLearningAISolutions/Testimonials';
import Partner from '../components/MachineLearningAISolutions/Partner';
import OurBlogEn from '../components/MachineLearningAISolutions/OurBlogEn';
import OurBlogVi from '../components/MachineLearningAISolutions/OurBlogVi';
import ProjectStartArea from '../components/MachineLearningAISolutions/ProjectStartArea';
import { useIntl } from "gatsby-plugin-intl"


const Home = () => {
  const intl = useIntl()
  return (
    <Layout>
      <NavbarTwo />
      <MainBanner />
      <Solutions />
      <AboutArea />
      <Services />
      <TeamMembers />
      <Testimonials /> 
      <Partner /> 
      {intl.locale == 'vi' ? <OurBlogVi /> : <OurBlogEn />}
      <ProjectStartArea /> 
      <Footer />
    </Layout>
  )
}

export default Home
import React from 'react';
import ReactWOW from 'react-wow';

import gdtd from '../../assets/images/partner/gdtd.png'
import ila from '../../assets/images/partner/ila.png'
import mages from '../../assets/images/partner/mages.png'
import starIcon from '../../assets/images/star-icon.png'
import kamala from '../../assets/images/partner/kamala.png'
import pn8 from '../../assets/images/partner/pn8.png'
import thanhnien from '../../assets/images/partner/thanhnien.png'
import { useIntl } from "gatsby-plugin-intl"

const Partner = () => {
    const intl = useIntl()
    return (
        <div className="partner-area pt-100 pb-70 bg-f1f8fb">
            <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="testimonial" /> 
                        {intl.formatMessage({ id: "home.Partner.SubTitle" })}
                    </span>
            </div>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-3 col-6 col-sm-6 col-md-6">
                        <ReactWOW delay='.1s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <img src={gdtd} alt="Partner" />
                                </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-3 col-6 col-sm-6 col-md-6">
                        <ReactWOW delay='.2s' animation='fadeInUp' className="justify-content-center">
                                <div className="single-partner-item">
                                    <img src={thanhnien} alt="Partner" />
                                </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-3 col-6 col-sm-6 col-md-6">
                        <ReactWOW delay='.3s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <img src={kamala} alt="Partner" />
                                </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-3 col-6 col-sm-6 col-md-6">
                        <ReactWOW delay='.4s' animation='fadeInUp'>
                                <div className="single-partner-item">
                                    <img src={pn8} alt="Partner" classNames="margin-bottom: 0px" />
                                </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-3 col-6 col-sm-6 col-md-6">
                        <ReactWOW delay='.5s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <img src={ila} alt="Partner" />
                            </div>
                        </ReactWOW>
                    </div>

                    <div className="col-lg-3 col-6 col-sm-6 col-md-6">
                        <ReactWOW delay='.6s' animation='fadeInUp'>
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <img src={mages} alt="Partner" />
                                </div>
                            </div>
                        </ReactWOW>
                    </div>
                </div>
            </div>

            <div className="divider"></div>
        </div>
    )
}

export default Partner;
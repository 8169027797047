import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import { useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import Image from 'gatsby-image'

const OurBlogEn = () => {
    const intl = useIntl()
    const { allMarkdownRemark } = useStaticQuery(
        graphql`
      query {
        allMarkdownRemark(
          filter: {frontmatter: {type: {eq: "blog",}}}
          sort: {fields: [frontmatter___date, frontmatter___language], order: [DESC, ASC]}
          limit: 3
        ) {
          edges {
            node {
              id
              frontmatter {
                type
                language
                title
                slug
                date
                cover {
                  childImageSharp {
                      fluid(maxWidth: 750, maxHeight: 650, quality: 100) {
                        ...GatsbyImageSharpFluid_noBase64
                      }
                  }
                }
                tags
                author
                aavatar {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 100, jpegQuality: 100) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
    )
    const posts = allMarkdownRemark.edges
    return (
        <section className="blog-area pt-100 pb-70 bg-fffbf5">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="blog" /> 
                        {intl.formatMessage({ id: "home.OurBlog.SubTitle" })}
                    </span>
                    <h2>{intl.formatMessage({ id: "home.OurBlog.H2SubTitle" })}</h2>
                    <p>{intl.formatMessage({ id: "home.OurBlog.PSubTitle" })}</p>
                </div>

                <div className="row">
                    {posts.map(post => {
                        const date = new Date(post.node.frontmatter.date);
                        const dateFormatted = new Intl.DateTimeFormat(
                            'en-US', { year: 'numeric', month: 'long', day: '2-digit' }
                        ).format(date)
                        return (
                            <div key={post.id} className="col-lg-4 col-md-6">
                                <div className="single-blog-post bg-fffbf5">
                                    <div className="post-image">
                                        <Link to={`/${intl.locale}/${post.node.frontmatter.slug}`}>
                                            <Image fluid={post.node.frontmatter.cover.childImageSharp.fluid} alt="thumbnail" />
                                        </Link>
                                    </div>

                                    <div className="post-content">
                                        <ul className="post-meta d-flex justify-content-between align-items-center">
                                            <li>
                                                <div className="post-author d-flex align-items-center">
                                                    <img src={post.node.frontmatter.aavatar.publicURL} className="rounded-circle" alt="image" />
                                                    <span>{post.node.frontmatter.author}</span>
                                                </div>
                                            </li>
                                            <li>
                                                <i className='flaticon-calendar'></i> {dateFormatted}
                                            </li>
                                        </ul>
                                        <h3>
                                            <Link to={`/${intl.locale}/${post.node.frontmatter.slug}`}>
                                              {post.node.frontmatter.title}
                                            </Link>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    )
}

export default OurBlogEn
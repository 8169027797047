import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import client1 from '../../assets/images/testimonials/client1.jpg'
import client2 from '../../assets/images/testimonials/client2.jpg'
import client3 from '../../assets/images/testimonials/client3.jpg'
import shape from '../../assets/images/shape/shape1.svg'
import Loadable from '@loadable/component'
import { useIntl } from "gatsby-plugin-intl"
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);
    const intl = useIntl()
    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <div className="testimonials-area pt-100 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="testimonial" /> 
                        {intl.formatMessage({ id: "home.Testimonials.SubTitle" })}
                    </span>
                    <h2>{intl.formatMessage({ id: "home.Testimonials.H2SubTitle" })}</h2>
                    <p>{intl.formatMessage({ id: "home.Testimonials.PSubTitle" })}</p>
                </div>

                {display ? <OwlCarousel 
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                > 
                    <div className="single-testimonials-item">
                        <p>{intl.formatMessage({ id: "home.Testimonials.Content.1" })}</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client1} alt="testimonial" />
                                <div className="title">
                                    <h3>{intl.formatMessage({ id: "home.Testimonials.Name.1" })}</h3>
                                    <span>{intl.formatMessage({ id: "home.Testimonials.Major.1" })}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>{intl.formatMessage({ id: "home.Testimonials.Content.2" })}</p>

                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client2} alt="testimonial" />
                                <div className="title">
                                    <h3>{intl.formatMessage({ id: "home.Testimonials.Name.2" })}</h3>
                                    <span>{intl.formatMessage({ id: "home.Testimonials.Major.2" })}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-item">
                        <p>{intl.formatMessage({ id: "home.Testimonials.Content.3" })}</p>
                        <div className="client-info">
                            <div className="d-flex justify-content-center align-items-center">
                                <img src={client3} alt="testimonial" />
                                <div className="title">
                                    <h3>{intl.formatMessage({ id: "home.Testimonials.Name.3" })}</h3>
                                    <span>{intl.formatMessage({ id: "home.Testimonials.Major.3" })}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </OwlCarousel> : ''}
            </div>

            <div className="shape-img1">
                <img src={shape} alt="testimonial" />
            </div>
        </div>
    )
}

export default Testimonials;